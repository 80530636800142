import { Button, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { MdEdit } from "react-icons/md";
import styled from "styled-components";

import { ProfileCard } from "../components/ProfileCard";
import { Seasons } from "../components/Season";
import { useAuth } from "../hooks/useAuth";
import { useUser } from "../hooks/useUser";

const ProfileLayout = styled.div`
  @media screen and (min-width: 960px) {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-gap: 40px;
  }
`;

const ProfileCardWrapper = styled.div`
  margin-bottom: 20px;
  @media screen and (min-width: 960px) {
    margin: 0;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 10px;
`;

export const Profile = () => {
  const { sendEmailVerification } = useAuth();
  const { user, isReady } = useUser();

  if (!isReady) {
    return <LinearProgress />;
  }

  const { hasCompletedProfile, isEmailVerified } = user;

  return (
    <ProfileLayout>
      <ProfileCardWrapper>
        <ProfileCard />
      </ProfileCardWrapper>
      <div>
        {user.email && !isEmailVerified && (
          <StyledAlert
            severity="warning"
            action={
              <Button onClick={() => sendEmailVerification()}>Verificēt</Button>
            }
          >
            Pirms vari sākt darboties, tev jāverificē savs e-pasts!
          </StyledAlert>
        )}
        {!hasCompletedProfile && (
          <StyledAlert severity="warning">
            Pirms vari sākt darboties, tev jāaizpilda savs profils! Spied uz
            zīmuļa ikonas (<MdEdit />) profila sekcijā.
          </StyledAlert>
        )}
        {isEmailVerified && hasCompletedProfile && <Seasons />}
      </div>
    </ProfileLayout>
  );
};
