import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Avatar as MuiAvatar,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  CircularProgress,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { MdSchool, MdEdit, MdMail, MdDelete, MdSave } from "react-icons/md";
import { Body, Heading3 } from "./Typography";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { graphql, useStaticQuery, navigate } from "gatsby";
import { useUser } from "../hooks/useUser";
import { FaQrcode } from "react-icons/fa";

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledAvatar = styled(MuiAvatar)`
  margin-bottom: 10px;
`;

const EditButton = styled(Button)`
  && {
    margin-bottom: 10px;
    margin-left: 0;
  }
`;

const Avatar = ({ displayName }) => {
  return (
    <StyledAvatar>
      {displayName
        ? displayName
            .split(" ")
            .map(([letter, ..._]) => letter)
            .join("")
        : "JFS"}
    </StyledAvatar>
  );
};

const ProfileView = ({ user, setIsEditting, isReady }) => {
  if (!isReady) {
    return (
      <StyledCardContent>
        <StyledSpinner />
      </StyledCardContent>
    );
  }

  const { email, displayName, profile } = user;
  const { school, schoolYear } = profile;

  return (
    <>
      <StyledCardContent>
        <Avatar displayName={displayName} />
        <Heading3>{displayName}</Heading3>
        <List>
          {!!email && (
            <ListItem>
              <ListItemIcon>
                <MdMail size={20} />
              </ListItemIcon>
              <Body>{email}</Body>
            </ListItem>
          )}
          {!!school && (
            <ListItem>
              <ListItemIcon>
                <MdSchool size={20} />
              </ListItemIcon>
              <div>
                <Body>{school.name}</Body>
                {!!schoolYear && (
                  <Body>
                    {schoolYear === "teacher"
                      ? "Skolotājs(-a)"
                      : schoolYear === "other"
                      ? "Cits"
                      : `${schoolYear}. klase`}
                  </Body>
                )}
              </div>
            </ListItem>
          )}
        </List>
      </StyledCardContent>
      <StyledCardActions>
        <EditButton
          startIcon={<MdEdit />}
          onClick={() => setIsEditting(true)}
          variant="contained"
          fullWidth={true}
        >
          Konta iestatījumi
        </EditButton>
        <EditButton
          startIcon={<FaQrcode />}
          onClick={() => navigate("/apmeklejums")}
          variant="contained"
          fullWidth={true}
        >
          Reģistrēt dalību
        </EditButton>
      </StyledCardActions>
    </>
  );
};

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
  width: 100%;
`;

const StyledAutocomplete = styled(Autocomplete)`
  margin-bottom: 20px;
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: 15px;
`;

const StyledSpinner = styled(CircularProgress)`
  margin-bottom: 10px;
`;

const SchoolSelect = ({
  schools,
  value,
  onChange,
  error,
  helperText,
  disabled,
}) => {
  return (
    <StyledAutocomplete
      value={value}
      onChange={(_, value) => onChange({ target: { value } })}
      options={schools}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(value, option) => option.id === value.id}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Skola"
          variant="outlined"
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

const ClassSelect = ({ value, onChange, error, helperText, disabled }) => {
  return (
    <StyledFormControl variant="outlined" error={error}>
      <InputLabel>Klase / Loma</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        disabled={disabled}
        label="Klase / Loma"
      >
        <MenuItem value={""}>-</MenuItem>
        <MenuItem value={8}>8. klase</MenuItem>
        <MenuItem value={9}>9. klase</MenuItem>
        <MenuItem value={10}>10. klase</MenuItem>
        <MenuItem value={11}>11. klase</MenuItem>
        <MenuItem value={12}>12. klase</MenuItem>
        <MenuItem value={"teacher"}>Skolotājs(-a)</MenuItem>
        <MenuItem value={"other"}>Cits</MenuItem>
      </Select>
      {!!error && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  );
};

const ProfileForm = ({ user, schools, onSubmit, isBusy }) => {
  const {
    displayName,
    email,
    canChangeEmail,
    profile: { school, schoolYear, emailsEnabled },
  } = user;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      displayName: displayName || "",
      email: email || "",
      school: school || null,
      schoolYear: schoolYear || "",
      emailsEnabled: emailsEnabled ?? true,
    },
  });
  const { deleteUser } = useUser();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  return (
    <form>
      <StyledCardContent>
        {isBusy ? <StyledSpinner /> : <Avatar displayName={displayName} />}
        <StyledTextField
          label="Vārds Uzvārds"
          variant="outlined"
          disabled={isBusy}
          error={!!errors.displayName}
          helperText={!!errors.displayName && "Vārds un uzvārds ir obligāti!"}
          {...register("displayName", { required: true, maxLength: 60 })}
        />
        <StyledTextField
          label="E-pasts"
          variant="outlined"
          disabled={isBusy}
          inputProps={{ readOnly: !canChangeEmail }}
          error={!!errors.email}
          helperText={!!errors.email && "Ievadi korektu e-pasta adresi!"}
          {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
        />
        <Controller
          control={control}
          name="school"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <SchoolSelect
              disabled={isBusy}
              schools={schools}
              value={value}
              onChange={onChange}
              error={!!errors.school}
              helperText={
                !!errors.school
                  ? "Izvēlies savu skolu!"
                  : "Ja neatrodi savu skolu sarakstā, ziņo mums, rakstot uz jaunofizikuskola@gmail.com. Kamēr mēs risināsim problēmu, izvēlies iespēju 'Cita skola'."
              }
            />
          )}
        />
        <Controller
          control={control}
          name="schoolYear"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <ClassSelect
              disabled={isBusy}
              value={value}
              onChange={onChange}
              error={!!errors.schoolYear}
              helperText={!!errors.schoolYear && "Izvēlies savu klasi!"}
            />
          )}
        />
        <StyledFormControl>
          <FormControlLabel
            control={
              <Controller
                name="emailsEnabled"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Switch
                    checked={!!value}
                    disabled={isBusy}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Vēlos saņemt e-pastus"
          />
          <FormHelperText variant="outlined">
            Periodiski informēsim par gaidāmajām nodarbībām un citām
            aktualitātēm.
          </FormHelperText>
        </StyledFormControl>
      </StyledCardContent>
      <StyledCardActions>
        <Dialog
          open={showDeleteConfirm}
          onClose={() => setShowDeleteConfirm(false)}
        >
          <DialogTitle>{"Tiešām vēlies dzēst savu kontu?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tiks dzēsti arī visi tavi testu rezultāti.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDeleteConfirm(false)} color="primary">
              Atcelt
            </Button>
            <Button
              onClick={() => {
                setShowDeleteConfirm(false);
                deleteUser();
              }}
              color="secondary"
            >
              Dzēst
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          startIcon={<MdDelete />}
          onClick={() => setShowDeleteConfirm(true)}
          disabled={isBusy}
          color="secondary"
          variant="contained"
        >
          Dzēst kontu
        </Button>
        <Button
          startIcon={<MdSave />}
          onClick={handleSubmit(onSubmit)}
          disabled={isBusy}
          variant="contained"
        >
          Saglabāt
        </Button>
      </StyledCardActions>
    </form>
  );
};

export const ProfileCard = () => {
  const {
    allSchoolsJson: { nodes: schools },
  } = useStaticQuery(
    graphql`
      query SchoolsQuery {
        allSchoolsJson {
          nodes {
            name
            id
          }
        }
      }
    `
  );
  const { user, updateUser, isReady, isBusy } = useUser();
  const [isEditting, setIsEditting] = useState(false);

  const onSubmit = async ({
    email,
    displayName,
    school,
    schoolYear,
    emailsEnabled,
  }) => {
    await updateUser({
      email,
      displayName,
      profile: {
        ...user.profile,
        fullName: displayName,
        school,
        schoolYear,
        emailsEnabled,
      },
    });
    setIsEditting(false);
  };

  return (
    <Card variant="outlined">
      {isEditting ? (
        <ProfileForm
          user={user}
          isBusy={isBusy}
          onSubmit={onSubmit}
          schools={schools}
        />
      ) : (
        <ProfileView
          user={user}
          isReady={isReady}
          setIsEditting={setIsEditting}
        />
      )}
    </Card>
  );
};
