import { navigate } from "gatsby";
import React from "react";

import { Layout } from "../components/Layout";
import { Profile } from "../components/Profile";
import Seo from "../components/SEO";
import { useAuth } from "../hooks/useAuth";

const ProfilePage = () => {
  const { isAuthenticated } = useAuth();

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate("/ienakt");
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Layout>
      <Seo title="Profils" />
      <Profile />
    </Layout>
  );
};

export default ProfilePage;
